import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Swiper from "react-id-swiper"
import axios from "axios"

import EmailCapture from "../components/EmailCapture"
import LinkTM from "../components/LinkTM"

import styles from "./discover.module.scss"
import emailStyles from "../components/emailCapture.module.scss"
import "./discover.scss"
import SEO from "../components/SEO"


const Discover = ({ data, location }) => {
  const inspirationHeading = data.inspiration.heading
  const inspirationItems = data.inspiration.items.reverse().slice(0,7)

  // const collaborationsHeading = data.collaborations.heading
  // const collaborationsItems = data.collaborations.items.reverse().slice(0,7)

  const [discoverItems, setDiscoverItems] = useState([])

  useEffect(() => {
    setDiscoverItems([...data.discover.items].reverse())
  }, [data.discover.items])

  const swiperParams = {
    containerClass: `${styles.swiperContainer} discover-swiper-container`,
    WrapperEl: "ol",
    threshold: 10,
    watchOverflow: true,
    preloadImage: true,
    // updateOnImagesReady: true,
    slidesPerView: "auto",
    spaceBetween: 0,
    speed: 10000,
    freeMode: true,
    loop: true,
    allowTouchMove: false,
  }

  const inspirationSwiperParams = {
    ...swiperParams,
    autoplay: {
      delay: 0,
      // disableOnInteraction: false,
      reverseDirection: false,
    },
  }

  // const collaborationsSwiperParams = {
  //   ...swiperParams,
  //   autoplay: {
  //     delay: 0,
  //     // disableOnInteraction: false,
  //     reverseDirection: true,
  //   },
  // }

  const headerHeight = (typeof window !== "undefined" && window.outerWidth < 1024) ? "11rem" : "15.5rem"

  const [latestOpen, setLatestOpen] = useState(false)

  const [temperature, setTemperarture] = useState(null)

  useEffect(() => {
    // Docs: https://openweathermap.org/current
    axios.get("http://api.openweathermap.org/data/2.5/weather", {
      params: {
        id: 5368361, // Los Angeles city ID
        units: "imperial",
        appid: "75d81dff934e73bfa1dcbf7e119c33c6",
      },
    })
      .then(({ data }) => {
        setTemperarture(Math.round(data.main.temp))
      })
  })

  return(
    <section className={styles.module}>
      <SEO
        title="Discover"
        description={data.discover.seoDescription}
        url={location.pathname}
      />

      <Link
        to="/discover/inspiration"
        className={styles.pageLinkInspiration}
      >
        <Swiper {...inspirationSwiperParams}>
          {inspirationItems.map((item, i) =>
            <li
              key={i}
              className={styles.swiperSlide}
              style={{ width: `calc((100vh - ${headerHeight}) / 2 * ${item.desktopImage.fluid.aspectRatio})` }}
            >
              <img src={item.desktopImage.fluid.src} alt={item.desktopImage.altText} />
            </li>
          )}
        </Swiper>
        <span>{inspirationHeading.heading}</span>
      </Link>
      {/*<Link*/}
      {/*  to="/discover/collaborations"*/}
      {/*  className={styles.pageLinkCollaborations}*/}
      {/*>*/}
      {/*  <Swiper {...collaborationsSwiperParams}>*/}
      {/*    {collaborationsItems.map((item, i) =>*/}
      {/*      <li*/}
      {/*        key={i}*/}
      {/*        className={styles.swiperSlide}*/}
      {/*        style={{ width: `calc((100vh - 19rem) / 2 * ${item.desktopImage.fluid.aspectRatio})` }}*/}
      {/*      >*/}
      {/*        <img src={item.desktopImage.fluid.src} alt={item.desktopImage.altText} />*/}
      {/*      </li>*/}
      {/*    )}*/}
      {/*  </Swiper>*/}
      {/*  <span>{collaborationsHeading.heading}</span>*/}
      {/*</Link>*/}
      <div className={(latestOpen) ? styles.latestContainerOpen : styles.latestContainer}>
        <button
          className={styles.latestBtn}
          onClick={() => {
            if (typeof window !== "undefined" && window.outerWidth < 1024)
              setLatestOpen(!latestOpen)
          }}
        >
          TM News
        </button>
        <div className={`${styles.listWrapper} ${emailStyles.discover}`}>
          <ul className={styles.list}>
            {discoverItems.map((item, i) => (
              <li key={i}>
                <LinkTM
                  url={item.url || item.pageUrl}
                  text={item.text || item.pageTitle}
                />
              </li>
            ))}
          </ul>
          <EmailCapture
            submitText="Sign Up"
            heading={{heading: "Sign up to stay updated with TM News"}}
            confirmationHeading={{heading: "Thank You"}}
            confirmationText="We’ll see you in your inbox."
            listId="UUpRVJ"
            source="discover"
            formName="Default"
            offerDiscountCode={false}
            welcomeSeries={true}
            location={location}
          />
        </div>
        {temperature &&
          <div className={styles.weather}>
            {temperature}&deg;F <span>Los Angeles, CA</span>
          </div>
        }
      </div>
    </section>
  )
}

export default Discover

export const query = graphql`
    query discoverQuery {
        inspiration: contentfulWorldPage(pageTitle: {eq: "TM World Inspiration"}) {
            heading {
                heading
            }
            items {
                ... on ContentfulImage {
                    altText
                    url
                    desktopImage {
                        fluid(maxWidth: 600, quality: 90) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
        collaborations: contentfulWorldPage(pageTitle: {eq: "TM World Collaborations"}) {
            heading {
                heading
            }
            items {
                ... on ContentfulImage {
                    altText
                    url
                    desktopImage {
                        fluid(maxWidth: 600, quality: 90) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
        discover: contentfulWorldPage(pageTitle: {eq: "TM World Discover"}) {
            seoDescription
            heading {
                heading
            }
            items {
                ... on ContentfulLink {
                    text
                    url
                }
                ... on ContentfulPage {
                    pageTitle
                    pageUrl
                }
            }
        }
    }
`
